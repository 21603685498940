import { Injectable, Optional, QueryList } from '@angular/core';
import { ColumnBase, ColumnComponent, GridComponent } from '@progress/kendo-angular-grid';
import { Observable, of, Subject, switchMap } from 'rxjs';
import { share, take, tap } from 'rxjs/operators';

export type ColumnChooserTableSettings = {
  field: string;
  hidden: boolean;
  orderIndex: number;
  optional?: boolean;
  includeInChooser?: boolean;
};

@Injectable()
export abstract class FireflyColumnChooserPersistenceService {
  abstract getTableSettings(
    tableId: string,
    columns: string[]
  ): Observable<Array<ColumnChooserTableSettings | null> | null>;
  abstract updateTableSettings(
    tableId: string,
    settings: Array<ColumnChooserTableSettings | null>
  ): Observable<unknown>;
  abstract resetTableSettings(tableId: string): Observable<unknown>;
}

@Injectable({ providedIn: 'root' })
export class FireflyColumnChooserService {
  private gridIdMap = new Map<string, GridComponent>();

  applyChangesSubj = new Subject<void>();
  applyChangesObs = this.applyChangesSubj.asObservable().pipe(share());

  constructor(@Optional() private columnChooserPersistenceService: FireflyColumnChooserPersistenceService) {}

  getSelectedFields(gridId: string) {
    const grid = this.gridIdMap.get(gridId);
    if (!grid) return [];
    return (grid.columns as QueryList<ColumnComponent>).filter(col => !col.hidden && !!col.field).map(col => col.field);
  }

  applyTableSettings(id: string, grid: GridComponent): void {
    this.gridIdMap.clear();
    this.gridIdMap.set(id, grid);
    const localSettings = this.getLocalSettings(id);
    const columns = grid.columns.map(col => (col as ColumnComponent).field).filter(Boolean);
    const tableSettings$ = this.columnChooserPersistenceService?.getTableSettings(id, columns) ?? of([]);
    const stickyColumns = grid.columns
      .filter(col => col.sticky)
      .map(col => ({ column: col, orderIndex: col.orderIndex }));

    tableSettings$.pipe(take(1)).subscribe(settings => {
      const crossBrowserSettings = JSON.stringify(settings) !== JSON.stringify(localSettings);
      settings =
        settings?.length !== 0 && crossBrowserSettings ? this.handleTableSettings(settings!, id) : localSettings;
      console.log(
        `Apply user preferences for ${id}: `,
        settings?.sort((a, b) => a!.orderIndex - b!.orderIndex)
      );

      grid.columns.forEach((col, index) => {
        col.orderIndex = index;
      });
      // Filtering out possible null columns from user preferences
      const filteredSettings = settings.filter(v => v !== null) as ColumnChooserTableSettings[];

      filteredSettings.forEach(setting => {
        const column = grid.columns.find(col => (col as ColumnComponent).field === setting!.field);
        if (column) {
          column.hidden = setting!.hidden;
          if (!column.sticky) {
            column.orderIndex = setting!.orderIndex;
          }
          if ((column as unknown as { optional: boolean }).optional) {
            column.includeInChooser = !!setting!.includeInChooser;
          }
        }
      });

      stickyColumns.forEach(sticky => grid.reorderColumn(sticky.column, sticky.orderIndex));
      this.applyChangesSubj.next();

      this.handleUnsetColumnsOrder(filteredSettings, grid.columns);
    });
  }

  updateTableSettings(id: string, columns: ColumnChooserTableSettings[], reset: boolean = false) {
    const settings = columns
      .filter(col => !!col.field)
      .map(col => {
        const setting: ColumnChooserTableSettings = {
          field: col.field,
          hidden: col.hidden,
          orderIndex: col.orderIndex
        };
        if (col.optional) {
          setting.optional = col.optional;
          setting.includeInChooser = col.includeInChooser;
        }
        return setting;
      });

    if (reset) console.log(`Delete user preferences for ${id}`);
    else if (settings.length) console.log(`Save user preferences for ${id}: `, settings);

    return of(reset ? this.getLocalSettings(id) : settings).pipe(
      tap(settings => {
        if (reset) {
          localStorage.removeItem(id);
          return;
        }
        const localSettings = this.getLocalSettings(id);
        settings.forEach(setting => {
          const matchIndex = localSettings.findIndex(s => setting.field === s.field);
          const match = localSettings[matchIndex];
          if (match) {
            if (match.optional && !setting.includeInChooser) {
              localSettings.splice(matchIndex, 1);
            } else {
              match.hidden = setting.hidden;
              match.orderIndex = setting.orderIndex;
              match.includeInChooser = setting.includeInChooser;
            }
          } else {
            localSettings.push(setting);
          }
        });
        localStorage.setItem(id, JSON.stringify(localSettings));
      }),
      switchMap(settings => {
        if (settings.length && this.columnChooserPersistenceService) {
          return reset
            ? this.columnChooserPersistenceService.resetTableSettings(id)
            : this.columnChooserPersistenceService.updateTableSettings(id, settings);
        } else {
          return of(true);
        }
      })
    );
  }

  private handleUnsetColumnsOrder(settings: ColumnChooserTableSettings[], columns: QueryList<ColumnBase>): void {
    const { columnsWithSameOrderIndexArray, unsetOrderIndexes } =
      this.getColumnsWithSameOrderIndexesAndUnsetOrderIndexes(columns);

    if (!columnsWithSameOrderIndexArray.length) return;

    let arrayIndex = 0;
    columnsWithSameOrderIndexArray.forEach(columnsWithSameOrderIndex => {
      columnsWithSameOrderIndex.forEach(column => {
        if (settings.some(setting => (column as ColumnComponent).field === setting.field)) return;
        column.orderIndex = unsetOrderIndexes[arrayIndex];
        arrayIndex++;
      });
    });
  }

  private getColumnsWithSameOrderIndexesAndUnsetOrderIndexes(columns: QueryList<ColumnBase>): {
    columnsWithSameOrderIndexArray: ColumnBase[][];
    unsetOrderIndexes: number[];
  } {
    const { itemsByOrderIndex, orderIndexArray } = this.getItemsByOrderIndexAndOrderIndexArray(columns);
    const columnsWithSameOrderIndexArray = Object.values(itemsByOrderIndex).filter(columns => columns.length > 1);
    const orderIndexSet = new Set(orderIndexArray);
    const unsetOrderIndexes = Array.from({ length: columns.length }, (_, i) => i + 1).filter(
      i => !orderIndexSet.has(i)
    );

    return { columnsWithSameOrderIndexArray, unsetOrderIndexes };
  }

  private getItemsByOrderIndexAndOrderIndexArray(columns: QueryList<ColumnBase>): {
    itemsByOrderIndex: Record<number, ColumnBase[]>;
    orderIndexArray: number[];
  } {
    const itemsByOrderIndex: Record<number, ColumnBase[]> = {};
    const orderIndexArray: number[] = [];

    columns.forEach(column => {
      const { orderIndex } = column;
      orderIndexArray.push(orderIndex);

      if (itemsByOrderIndex[orderIndex]) {
        itemsByOrderIndex[orderIndex].push(column);
      } else {
        itemsByOrderIndex[orderIndex] = [column];
      }
    });

    return { itemsByOrderIndex, orderIndexArray };
  }

  private getLocalSettings(id: string) {
    const localConfig = localStorage.getItem(id);
    return localConfig ? (JSON.parse(localConfig) as ColumnChooserTableSettings[]) : [];
  }

  private handleTableSettings(
    settings: Array<ColumnChooserTableSettings | null>,
    id: string
  ): Array<ColumnChooserTableSettings | null> {
    localStorage.removeItem(id);

    if (settings === null) {
      settings = [];
    } else if (settings.length) {
      localStorage.setItem(id, JSON.stringify(settings));
    }

    return settings;
  }
}
