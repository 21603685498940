import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import { ColumnBase, ColumnComponent } from '@progress/kendo-angular-grid';
import { ExportableColumnDirective } from '../exportable/exportable-column.directive';

export function columnsFactory(baseColumnsHost: { columns: ColumnBase[] }) {
  return baseColumnsHost.columns;
}

@Component({
  selector: 'ca-custom-fields-columns',
  templateUrl: './custom-fields-columns.component.html',
  providers: [
    {
      provide: ColumnBase,
      useFactory: columnsFactory,
      deps: [CustomFieldsColumnsComponent]
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomFieldsColumnsComponent implements OnInit {
  @ViewChildren(ExportableColumnDirective) exportableColumns!: QueryList<ExportableColumnDirective>;
  @ViewChildren(ColumnComponent) columnQuery!: QueryList<ColumnComponent>;

  columns: ColumnComponent[] = [];

  @Input() media!: string;
  @Input() customFieldsColumns: {
    name: string;
    width: number;
    type?: string;
    cssClass?: string | string[];
    headerClass?: string | string[];
    id?: number;
  }[] = [];

  @Input() sortable = false;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.columns = this.columnQuery.toArray();
  }
}
