import { createAction, props } from '@ngrx/store';

export const createProfileReport = createAction(
  scoped`Create Profile Report`,
  props<{
    profiles: { prId?: number; crmId?: number; name?: string }[];
    profileType: 'contact' | 'fund' | 'institution';
    useCustomSettings?: boolean;
  }>()
);

export const createProfileReportStarted = createAction(scoped`Create Profile Report Started`);
export const createProfileReportFinished = createAction(scoped`Create Profile Report Finished`);

export const openProfileReportDrawer = createAction(
  scoped`Open Profile Report Drawer`,
  props<{
    profiles: { prId?: number | null; crmId?: number | null; name?: string | null }[];
    profileType: 'contact' | 'fund' | 'institution';
  }>()
);

export const createHistoricalOwnershipReportFinished = createAction(
  scoped`Create Historical Ownership report Finished`
);

export const openHistoricalOwnershipReportDrawer = createAction(
  scoped`Open Historical Ownership Report Drawer`,
  props<{ reportedQuarters: string[] }>()
);

export const createItineraryReport = createAction(
  scoped`Create Itinerary Report`,
  props<{
    eventId: string;
    eventName: string;
  }>()
);

export const createItineraryReportFinished = createAction(
  scoped`Create Itinerary Report Finished`,
  props<{
    eventId: string;
  }>()
);

function scoped(templateString: TemplateStringsArray) {
  return '[Common Reporting] ' + templateString[0];
}
