<div class="overflow-hidden">
  <ng-content></ng-content>
</div>
<button
  class="btn btn-simple-icon btn-rounded ms-4"
  [ngClass]="buttonClass"
  (click)="onActionIconClick()"
  *ngIf="showActionIcon"
>
  <i class="f-i f-i-md ms-0" [ngClass]="iconClass" [title]="iconTitle$ | async"></i>
</button>
<i
  class="f-i f-i-xs f-i-check toggle-list-item-icon-check"
  [@iconAnimation]
  (@iconAnimation.done)="captureAnimationDoneEvent($event)"
  *ngIf="showCheckIcon"
></i>
