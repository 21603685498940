import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonLocalizationModule } from '@capital-access/common/localization';
import { LOCALIZATION_KEY } from './constants';
import { IsProfileReportingDisabledPipe } from './pipes/is-profile-reporting-disabled.pipe';
import { ReportGenerationTooltipPipe } from './pipes/report-generation-tooltip.pipe';
import { ReportIsExportingTooltipPipe } from './pipes/report-is-exporting-tooltip.pipe';

@NgModule({
  imports: [CommonModule, CommonLocalizationModule.forFeature(LOCALIZATION_KEY)],
  declarations: [IsProfileReportingDisabledPipe, ReportGenerationTooltipPipe, ReportIsExportingTooltipPipe],
  exports: [IsProfileReportingDisabledPipe, ReportGenerationTooltipPipe, ReportIsExportingTooltipPipe],
  providers: [IsProfileReportingDisabledPipe, ReportGenerationTooltipPipe, ReportIsExportingTooltipPipe]
})
export class ReportingCommonModule {}
