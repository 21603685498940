import { Inject, Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, of, switchMap, tap, throwError } from 'rxjs';
import { saveSerializedSettings, saveSettingsFailure, saveSettingsSuccess } from '@capital-access/common/user';
import { ColumnChooserTableSettings, FireflyColumnChooserPersistenceService } from '@capital-access/firefly/components';
import { getTableSettings } from './+state/table-settings.selectors';
import { APP_TABLE_SETTINGS_PREFIX } from './table-settings.module';

@Injectable()
export class ColumnChooserPersistenceService implements FireflyColumnChooserPersistenceService {
  constructor(
    private store: Store,
    private actions: Actions,
    @Inject(APP_TABLE_SETTINGS_PREFIX) private appPrefix: string
  ) {}

  getTableSettings(tableId: string, columns: string[]) {
    const path = this.getTableSettingsSection(this.appPrefix, tableId);
    return this.store.select(getTableSettings(path)).pipe(
      map(({ settings }) => {
        return Object.values(settings).length ? this.mapEncodedTableSettings(columns, settings) : null;
      }),
      catchError(() => of(null))
    );
  }

  updateTableSettings(tableId: string, tableSettings: ColumnChooserTableSettings[]) {
    const path = this.getTableSettingsSection(this.appPrefix, tableId);
    const settings = tableSettings.map(col => ({
      id: `${path}:${this.encodeField(col.field)}`,
      value: JSON.stringify(col)
    }));

    this.store.dispatch(saveSerializedSettings({ data: settings }));

    return this.actions.pipe(
      ofType(saveSettingsSuccess, saveSettingsFailure),
      switchMap(res => (res.type === saveSettingsSuccess.type ? of(res.settings) : throwError(res.error)))
    );
  }

  resetTableSettings(tableId: string) {
    const path = this.getTableSettingsSection(this.appPrefix, tableId);
    return this.store.select(getTableSettings(path)).pipe(
      map(({ settings }) => {
        const nullSettings = [];
        for (const key in settings) {
          nullSettings.push({ id: `${path}:${key}`, value: null });
        }
        return nullSettings;
      }),
      tap(data => this.store.dispatch(saveSerializedSettings({ data }))),
      switchMap(() => {
        return this.actions.pipe(
          ofType(saveSettingsSuccess, saveSettingsFailure),
          switchMap(res => (res.type === saveSettingsSuccess.type ? of(res.settings) : throwError(res.error)))
        );
      })
    );
  }

  private mapEncodedTableSettings(columns: string[], settings: Record<string, ColumnChooserTableSettings>) {
    const res: ColumnChooserTableSettings[] = [];

    columns.forEach(col => {
      const encodedFieldMatch = settings[this.encodeField(col)];
      const regularFieldMatch = settings[col];

      if (encodedFieldMatch) {
        res.push(encodedFieldMatch);
      } else if (regularFieldMatch) {
        res.push(regularFieldMatch);
      }
    });

    return res;
  }

  private getTableSettingsSection(appPrefix: string, tableId: string) {
    return `${appPrefix}:table-settings:${tableId}`;
  }

  private encodeField(field: string): string {
    return btoa(field).replace(/[=]/g, '');
  }
}
