/* eslint-disable @typescript-eslint/no-explicit-any */

import { isString } from '../type-checks';

const isBlank = (value: unknown) => !value && value !== 0;
const isBlankString = (value: string) => value === '';

function stringCompare(a: string, b: string, collator: Intl.Collator, isDesc: boolean = false): number {
  if (isBlankString(a) && isBlankString(b)) {
    return 0;
  }
  if (isBlankString(a)) {
    return 1;
  } else if (isBlankString(b)) {
    return -1;
  }

  return collator.compare(a, b) * (isDesc ? -1 : 1);
}

function genericCompare(a: any, b: any, isDesc: boolean = false): number {
  if (isBlank(a) && isBlank(b)) {
    return 0;
  }
  if (isBlank(a)) {
    return 1;
  } else if (isBlank(b)) {
    return -1;
  } else if (a === b) {
    return 0;
  } else if (isDesc) {
    return a < b ? 1 : -1;
  } else {
    return a < b ? -1 : 1;
  }
}

export function createCollator(needNumericSorting: boolean): Intl.Collator {
  const compareOptions = { caseFirst: 'upper', sensitivity: 'case', numeric: needNumericSorting };
  return new Intl.Collator(undefined, compareOptions);
}

export function compare(a: any, b: any, collator: Intl.Collator, isDesc: boolean = false): number {
  const _isString = isString(a) && isString(b);
  return _isString ? stringCompare(a, b, collator, isDesc) : genericCompare(a, b, isDesc);
}
