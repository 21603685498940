import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatestWith, tap, withLatestFrom } from 'rxjs/operators';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { loadUserProfileSuccess, saveSettingsSuccess } from '@capital-access/common/user';
import { fallBackTheme } from '../models/branding';
import { ThemingService } from '../services/theming.service';
import { getTheme } from './theming.selectors';

@Injectable()
export class ThemingEffects {
  currentTheme = fallBackTheme;

  onLoadUserProfileSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadUserProfileSuccess),
        withLatestFrom(this.store.select(getTheme)),
        tap(([_, theme]) => this.setTheme(theme)),
        combineLatestWith(this.store.select(getFeatureToggle('CPD-1326-mobile-baseline-size'))),
        tap(([_, sizeIncreaseAvailable]) => {
          if (sizeIncreaseAvailable) this.themingService.setSizeIncrease();
        })
      ),
    { dispatch: false }
  );

  onSaveSettingsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveSettingsSuccess),
        withLatestFrom(this.store.select(getTheme)),
        tap(([_, theme]) => this.setTheme(theme))
      ),
    { dispatch: false }
  );

  private setTheme(theme: string) {
    if (theme !== this.currentTheme) {
      this.themingService.setTheme(theme);
      this.currentTheme = theme;
    }
  }

  constructor(private store: Store, private actions$: Actions, private themingService: ThemingService) {}
}
