import { type, value } from '@capital-access/common/user';

export const brandingConfig = {
  snp: {
    alias: 'S&P',
    modes: ['light', 'dark'],
    getTheme: function (mode = 'light') {
      return this.modes.includes(mode) ? `snp-${mode}` : 'snp-light';
    },
    getThemeColor: function (mode = 'light') {
      return mode === 'light' ? `#fafafa` : '#313336';
    }
  }
};

export type SupportedBranding = keyof typeof brandingConfig;
export const supportedBrandsList = Object.keys(brandingConfig);
export const fallBackTheme = brandingConfig.snp.getTheme();
export const fallBackBranding = 'snp';
export const fallBackMode = 'light';

export type BrandingKey = 'branding' | 'mode';
export const APPLICATION_KEY = 'application';

export const brandingMap: Record<BrandingKey, string> = {
  branding: 'branding',
  mode: 'branding:mode'
};

export function getBrandingKey(type: BrandingKey) {
  const key = brandingMap[type];
  return `${APPLICATION_KEY}:${key}`;
}

export class BrandingSettings {
  @value(type.string, { path: brandingMap['branding'] }) brand = fallBackBranding;
}

export class BrandingModeSettings {
  @value(type.string) mode = fallBackMode;
}
