import { createAction, props } from '@ngrx/store';
import { saveSettings } from '@capital-access/common/user';
import { DEFAULT_SECURITY_LIST_KEY } from '../../constants';
import { DefaultSecurityListSettings, ListType, ProfileId } from '../../models';
import { List } from '../../models';

export const addNewList = createAction(
  '[Common Lists] Add New List',
  (payload?: { listType?: ListType; showBackButton?: boolean }) => payload || {}
);

const addToListPayload = props<{ listType: ListType; ids: { prId?: number | null; crmId?: number | null }[] }>();

export const addProfilesToNewList = createAction('[Lists Common Actions] Add Profiles To New List', addToListPayload);

export const addProfilesToExistingList = createAction(
  '[Lists Common Actions] Add Profiles To Existing List',
  addToListPayload
);

export const showListDetailedView = createAction(
  '[Lists Common Actions] Show List Detailed View',
  props<{ list: List; showBackButton?: boolean }>()
);

export const deleteListSuccess = createAction(
  '[Lists Common Actions] Delete List Success',
  props<{ listId: number; listType: ListType }>()
);

export const addProfilesToListSuccess = createAction(
  '[Lists Common Actions] Add Profile To List Success',
  props<{ list: List; items: ProfileId[] }>()
);
export const createProfileListSuccess = createAction(
  '[Lists Common Actions] Create Profile List Success',
  props<{ list: List; items: ProfileId[] }>()
);
export const createSecurityListSuccess = createAction(
  '[Lists Common Actions] Create Security List Success',
  props<{ list: List; items: number[] }>()
);

export const removeProfilesFromListSuccess = createAction(
  '[Lists Common Actions] Remove Profile From List Success',
  props<{ list: List; items: ProfileId[]; featureKey: string }>()
);

export const editList = createAction(
  '[Lists Common Actions] Edit List',
  props<{ list: List; showBackButton?: boolean }>()
);

export const editProfileListSuccess = createAction(
  '[Lists Common Actions] Edit Profile List Success',
  props<{ list: List; removedItemsIds: ProfileId[]; addedItemsIds: ProfileId[] }>()
);
export const editSecurityListSuccess = createAction(
  '[Lists Common Actions] Edit Security List Success',
  props<{ list: List }>()
);
export const openListManagement = createAction(
  '[Lists Common Actions] Open List Management',
  (payload?: { listType?: ListType }) => payload || {}
);

export const saveDefaultSecurityList = (data: DefaultSecurityListSettings) =>
  saveSettings(DefaultSecurityListSettings, { section: DEFAULT_SECURITY_LIST_KEY, data });
