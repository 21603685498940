import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalizationService } from '@capital-access/common/localization';
import { Log } from '@capital-access/common/logging';
import { ChartLegendOptions } from '@capital-access/firefly/components';
import { COMMON_COMPONENTS_LOCALIZATION_SCOPE } from '../common-components.const';
import { ConcentrationChartColorScheme, DecileHighlightStrategy, DecilesIndexRange, FactorInfo } from './models';
import { getConcentrationGradientCssClass, getConcentrationLevelCssClasses, getHighlightedDecilesRange } from './utils';

@Component({
  selector: 'ca-concentration-chart',
  templateUrl: './concentration-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConcentrationChartComponent implements OnInit, OnChanges {
  private _decilesHighlightRange = 1;

  @Input() securityName!: string;
  @Input() factorInfo!: FactorInfo;
  @Input() showPopover = true;
  @Input() showLegend = true;
  @Input() decilesHighlightStrategy = DecileHighlightStrategy.Centric;
  @Input() colorScheme = ConcentrationChartColorScheme.Scheme_0;
  @Input() get decilesHighlightRange() {
    return this._decilesHighlightRange;
  }
  set decilesHighlightRange(value: number) {
    if (value < 1) {
      Log.warn(`decilesHighlightRange can't be less then 1. Reset to 1`);
      this._decilesHighlightRange = 1;
    } else {
      this._decilesHighlightRange = value;
    }
  }

  concentrationCssClasses!: string[];
  legendOptions!: ChartLegendOptions;
  public calculatedDecilesIndexRange!: DecilesIndexRange;

  valueTitle!: Observable<string>;
  securityDecile!: Observable<string>;
  calculatedDecileRange!: Observable<string>;
  topFactorValues!: Observable<string>;

  constructor(@Optional() private localizationService: LocalizationService) {}

  get securityDecileIndex() {
    return this.factorInfo.securityDecileIndex ?? -1;
  }

  get mostDecilesRange() {
    const ascDecileValues = [...this.factorInfo.decile.values].sort((a, b) => a - b);
    return ascDecileValues.slice(-3);
  }

  get calculatedDecilesValueRange() {
    const indexRange = this.calculatedDecilesIndexRange;

    const valueRange = this.factorInfo.decile.values
      .slice(indexRange.startIndex, indexRange.endIndex + 1)
      .sort((a, b) => a - b);

    return [valueRange[0], valueRange[valueRange.length - 1]];
  }

  ngOnInit() {
    this.concentrationCssClasses = getConcentrationLevelCssClasses(this.colorScheme);
    this.legendOptions = this.makeLegend();
    this.calculatedDecilesIndexRange = getHighlightedDecilesRange(
      this.factorInfo.securityDecileIndex as number,
      this.decilesHighlightRange,
      this.decilesHighlightStrategy
    );

    if (this.localizationService) {
      this.valueTitle = this.localizationService.localize(
        'concentrationChartValue',
        {},
        COMMON_COMPONENTS_LOCALIZATION_SCOPE
      );
      this.securityDecile = this.localizationService.localize(
        'concentrationChartSecurityDecile',
        { securityName: this.securityName },
        COMMON_COMPONENTS_LOCALIZATION_SCOPE
      );
      this.calculatedDecileRange = this.localizationService.localize(
        'concentrationChartCalculatedRange',
        {},
        COMMON_COMPONENTS_LOCALIZATION_SCOPE
      );
      this.topFactorValues = this.localizationService.localize(
        'concentrationChartTopFactorValues',
        {},
        COMMON_COMPONENTS_LOCALIZATION_SCOPE
      );

      this.localizationService
        .localize(
          ['concentrationChartLegendConcentration', 'concentrationChartLegendCalculatedSegment'],
          {},
          COMMON_COMPONENTS_LOCALIZATION_SCOPE
        )
        .subscribe(([concentrationTitle, calculatedSegment]) => {
          this.legendOptions.data[1].label = calculatedSegment;
          this.legendOptions.data[2].label = concentrationTitle;
        });
    } else {
      this.valueTitle = of('Value');
      this.securityDecile = of(`${this.securityName} Decile`);
      this.calculatedDecileRange = of('Calculated Decile Range');
      this.topFactorValues = of('Top Concentration in Factor Values');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.factorInfo?.previousValue &&
        changes.factorInfo.currentValue.securityDecileIndex !== changes.factorInfo.previousValue.securityDecileIndex) ||
      (changes.decilesHighlightRange?.previousValue &&
        changes.decilesHighlightRange.currentValue !== changes.decilesHighlightRange.previousValue) ||
      (changes.decilesHighlightStrategy?.previousValue &&
        changes.decilesHighlightStrategy.currentValue !== changes.decilesHighlightStrategy.previousValue)
    ) {
      this.calculatedDecilesIndexRange = getHighlightedDecilesRange(
        this.factorInfo.securityDecileIndex as number,
        this.decilesHighlightRange,
        this.decilesHighlightStrategy
      );
      this.legendOptions = this.makeLegend();
    }
  }

  private makeLegend(): ChartLegendOptions {
    const data = [
      { label: this.securityName, markerCssClass: 'rounded-pill ca-selected-decile-bubble' },
      { label: 'Calculated Segment', markerCssClass: 'legend-line ca-highlighted-decile ms-3' },
      {
        label: 'Holdings Concentration',
        markerCssClass: `${getConcentrationGradientCssClass(
          this.colorScheme
        )} legend-decile-concentration-marker me-1 ms-3`
      }
    ];

    return {
      data,
      legendItemsMarginSize: 0,
      legendPosition: 'bottom',
      legendContentAlignment: 'center',
      legendWrappingLinesCount: 3
    };
  }
}
