import { animate, state, style, transition, trigger } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import type { ScaleBand, ScaleLinear, ScalePoint } from 'd3-scale';
import { area, curveLinear, curveMonotoneX, line } from 'd3-shape';
import isNull from 'lodash/isNull';
import { Breakpoint } from '../../../../utils';
import type { ChartCircleDto, ChartDataEntry } from '../../models/common-chart-models';

const animationState = [
  state('initial', style({ d: 'path("{{startPath}}")' }), { params: { startPath: '' } }),
  state('final', style({ d: 'path("{{endPath}}")' }), { params: { endPath: '' } }),
  transition('initial => final', [animate('{{duration}}ms {{delay}}ms ease')], {
    params: { duration: 0, delay: 0 }
  })
];

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'g[f-line]',
  templateUrl: './line.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('lineAnimation', [...animationState]), trigger('areaAnimation', [...animationState])]
})
export class FireflyLineComponent implements OnInit, OnChanges {
  @Input() xScale!: ScalePoint<string> | ScaleBand<string>;
  @Input() yScale!: ScaleLinear<number, number>;
  @Input() data: ChartDataEntry[] = [];
  @Input() lineClasses!: string | string[];
  @Input() areaClasses!: string | string[];
  @Input() animationDuration = 500;
  @Input() animationDelay = 0;
  @Input() animation = true;
  @Input() curved = false;
  @Input() lineWidth = 2;
  @Input() circleRadius = 4;
  @Input() popoverOpenDelay = 200;
  @Input() popoverPlacement = 'top';
  @Input() popoverTriggers = 'manual';
  @Input() popoverTemplate!: TemplateRef<unknown> | null;
  @Input() areaIsSelected = false;
  @Input() showPointerLine = false;
  @Input() showArea = false;
  /**
   * Inputs used for bar-line chart interactions, like animating circle-pointer movements.
   */
  @Input() activeDataPointId = -1;
  @Input() nearestXPointer!: number | undefined | null;

  @ViewChild('popover', { static: true, read: NgbPopover }) popoverRef!: NgbPopover;
  @ViewChild('motion', { static: false }) motion!: ElementRef;
  @ViewChild('line', { static: true }) line!: ElementRef;

  popoverTimerId!: number;
  popoverClass = 'f-chart-popover';
  transitionDuration = 120;
  pointerLinePositionData!: ChartCircleDto | undefined;
  pointerPositionData!: ChartCircleDto | undefined;
  areaAnimationIsDone = false;
  animationState = 'initial';

  private minXDiff = Number.MAX_VALUE;

  get isMobile() {
    return window.innerWidth < Breakpoint.Sm;
  }

  get pointerEl(): HTMLElement {
    return (this.popoverRef as unknown as { _elementRef: ElementRef })._elementRef.nativeElement;
  }

  get linePath() {
    return this.getLinePath();
  }

  get defaultLinePath() {
    return this.getLinePath(true);
  }

  get areaPath() {
    return this.getAreaPath();
  }

  get defaultAreaPath() {
    return this.getAreaPath(true);
  }

  get pointerTranslateFn() {
    return `translate(${this.pointerPositionData?.cx ?? 0}, 0)`;
  }

  get pointerLineTranslateFn() {
    return `translate(${this.pointerLinePositionData?.cx ?? 0}, 0)`;
  }

  get lineStyles() {
    const afterAnimationOpacity = this.areaAnimationIsDone ? 1 : 0;
    const opacity = this.showArea ? afterAnimationOpacity : 1;
    return { opacity, transition: `opacity ${this.transitionDuration}ms ease` };
  }

  constructor(private zone: NgZone, private cdr: ChangeDetectorRef, private r2: Renderer2) {}

  ngOnInit() {
    requestAnimationFrame(() => {
      this.animationState = 'final';
      this.cdr.detectChanges();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.zone.runOutsideAngular(() => {
      if (this.data[this.activeDataPointId]?.value === null) return;

      if (changes.activeDataPointId && !changes.activeDataPointId.firstChange) {
        const { currentValue } = changes.activeDataPointId;
        if (currentValue == -1) {
          this.r2.setStyle(this.pointerEl, 'opacity', '0');
          this.popoverRef.close(false);
        }
      }

      if (changes.nearestXPointer) {
        const xCoordinate = changes.nearestXPointer.currentValue;
        const bandWidth = Math.round(this.xScale.bandwidth());
        this.minXDiff = Number.MAX_VALUE;
        let xPositionDiff = -Number.MAX_VALUE;

        this.pointerPositionData = this.data
          .map(d => {
            const name = d.name;
            const x = this.xScale(name)! || 0;
            const value = isNull(d.value) ? 0 : d.value!;
            const cx = this.xScale(name)! + bandWidth / 2 || 0;
            const cy = this.yScale(value!) || 0;

            if (Number.isFinite(xCoordinate)) {
              xPositionDiff = Math.abs(xCoordinate - x);
              if (xPositionDiff < this.minXDiff) this.minXDiff = xPositionDiff;
            }

            return { cx, cy, xPositionDiff, name, value, cssClass: [], popoverData: { ...d } };
          })
          .find(circle => circle.xPositionDiff === this.minXDiff);

        const withTransition = changes?.activeDataPointId?.previousValue !== -1;
        this.handlePointerPosition(this.pointerPositionData, withTransition);
        this.handlePopover();
      }

      if (changes.areaIsSelected) {
        this.pointerLinePositionData = this.pointerPositionData;
      }

      if (changes.xScale && !changes.xScale.firstChange && this.xScale) {
        const bandWidth = Math.round(this.xScale.bandwidth());
        if (this.pointerPositionData) {
          this.pointerPositionData.cx = this.xScale(this.pointerPositionData.name)! + bandWidth / 2 || 0;
        }
        if (this.pointerLinePositionData) {
          this.pointerLinePositionData.cx = this.xScale(this.pointerLinePositionData.name)! + bandWidth / 2 || 0;
        }
      }
    });
  }

  onLineAnimationEnd($event: { element: HTMLElement }) {
    requestAnimationFrame(() => {
      this.r2.setAttribute($event.element, 'd', this.linePath(this.data) as string);
    });
  }

  onAreaAnimationEnd($event: { element: HTMLElement; toState: string }) {
    this.areaAnimationIsDone = $event.toState === 'final';
    requestAnimationFrame(() => {
      this.r2.setAttribute($event.element, 'd', this.areaPath(this.data) as string);
    });
  }

  private getAreaPath(defaultPath = false) {
    return area<ChartDataEntry>()
      .curve(this.curved ? curveMonotoneX : curveLinear)
      .x(d => this.xScale(d?.name)! + this.xScale.bandwidth() / 2)
      .y0(() => this.yScale.range()[0])
      .y1(d => this.yScale(defaultPath ? this.yScale.domain()[0] : d.value!))
      .defined(d => d.value !== null);
  }

  private getLinePath(defaultPath = false) {
    return line<ChartDataEntry>()
      .curve(this.curved ? curveMonotoneX : curveLinear)
      .x(d => this.xScale(d?.name)! + this.xScale.bandwidth() / 2)
      .y(d => this.yScale(defaultPath ? this.yScale.domain()[0] : d.value!))
      .defined(d => d.value !== null);
  }

  private handlePopover() {
    if (!this.popoverTemplate) return;
    if (!this.pointerPositionData) {
      this.popoverRef.close(false);
    } else {
      this.zone.runTask(() => this.openPopover());
    }
  }

  private openPopover() {
    if (window.innerWidth < Breakpoint.Sm) return;
    if (!this.popoverRef.isOpen()) {
      this.popoverTimerId = setTimeout(() => this.popoverRef.open(), this.popoverOpenDelay) as unknown as number;
    } else {
      this.popoverRef.close(false);
      clearTimeout(this.popoverTimerId);
      this.popoverTimerId = setTimeout(() => this.popoverRef.open(), this.popoverOpenDelay) as unknown as number;
    }
  }

  private handlePointerPosition(pointerPositionData: ChartCircleDto | undefined, transition = true) {
    if (!pointerPositionData) return;

    this.r2.setStyle(this.pointerEl, 'transition', `opacity ${this.transitionDuration / 2}ms`);

    requestAnimationFrame(() => {
      this.r2.setStyle(this.line.nativeElement, 'opacity', '1');
      this.r2.setStyle(this.pointerEl, 'opacity', '1');

      this.cdr.detectChanges();

      if (this.animation && transition && !this.isMobile) {
        const cxTransitionFn = `cx ${this.transitionDuration}ms ease-out`;
        const cyTransitionFn = `cy ${this.transitionDuration}ms ease-out`;
        const opacityTransitionFn = `opacity ${this.transitionDuration / 2}ms `;
        this.r2.setStyle(this.pointerEl, 'transition', `${cxTransitionFn}, ${cyTransitionFn}, ${opacityTransitionFn}`);
      }

      this.r2.setAttribute(this.pointerEl, 'cx', `${pointerPositionData.cx}`);
      this.r2.setAttribute(this.pointerEl, 'cy', `${pointerPositionData.cy}`);
    });
  }
}
