import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  Renderer2,
  ViewChild
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Observable, Subject, takeUntil, tap } from 'rxjs';
import { NavigationItemsService, SideNavigationMap } from '@capital-access/common/navigation';

@Component({
  selector: 'ca-navigation',
  templateUrl: './navigation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationComponent implements AfterViewInit, OnDestroy {
  @ViewChild('scrollableElement') scrollableElement!: ElementRef;
  flexibleMobileNav = true;

  sideNavigationMap$: Observable<SideNavigationMap>;
  private destroyed$ = new Subject<void>();

  constructor(navigationItemsService: NavigationItemsService, private router: Router, private renderer: Renderer2) {
    this.sideNavigationMap$ = navigationItemsService.getSideNavigationMap();
  }

  ngAfterViewInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        tap(() => this.renderer.setProperty(this.scrollableElement.nativeElement, 'scrollTop', 0)),
        takeUntil(this.destroyed$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
