import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataResponse, toHttpParamsObject } from '@capital-access/common/utils';
import { CiqEvent, CiqEventDetail, CiqEventRequest } from './models';

@Injectable()
export class CiqEventRepository {
  private baseUrl: string = '/api/events-service';

  constructor(private httpClient: HttpClient) {}

  getEvents(eventTypeId: number, request: CiqEventRequest): Observable<DataResponse<CiqEvent>> {
    return this.httpClient.get<DataResponse<CiqEvent>>(`${this.baseUrl}/types/${eventTypeId}/events`, {
      params: toHttpParamsObject(request)
    });
  }

  getEvent(eventId: number): Observable<CiqEventDetail> {
    return this.httpClient.get<CiqEventDetail>(`${this.baseUrl}/events/${eventId}`);
  }
}
