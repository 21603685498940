<svg class="overflow-visible" style="pointer-events: none">
  <path
    *ngIf="showArea"
    class="f-area"
    fill="grey"
    stroke="transparent"
    stroke-linejoin="round"
    [@.disabled]="!animation"
    [@areaAnimation]="{
      value: animationState,
      params: {
        startPath: defaultAreaPath(data),
        endPath: areaPath(data),
        duration: animationDuration - transitionDuration,
        delay: animationDelay
      }
    }"
    (@areaAnimation.done)="onAreaAnimationEnd($event)"
    [ngClass]="areaClasses"
  />
  <path
    #line
    class="f-line"
    fill="none"
    stroke="grey"
    stroke-linejoin="round"
    [ngStyle]="lineStyles"
    [@.disabled]="!animation || showArea"
    [@lineAnimation]="{
      value: animationState,
      params: {
        startPath: defaultLinePath(data),
        endPath: linePath(data),
        duration: animationDuration,
        delay: animationDelay
      }
    }"
    (@lineAnimation.done)="onLineAnimationEnd($event)"
    [attr.stroke-width]="lineWidth"
    [ngClass]="lineClasses"
  />
  <line
    y1="0"
    y2="100%"
    class="f-area-pointer-line selected"
    [class.show]="areaIsSelected && pointerLinePositionData?.cx"
    [attr.transform]="pointerLineTranslateFn"
    *ngIf="showArea && showPointerLine"
  />
  <line
    y1="0"
    y2="100%"
    class="f-area-pointer-line"
    [class.show]="pointerPositionData?.cx"
    [attr.transform]="pointerTranslateFn"
    *ngIf="showPointerLine"
  />
  <circle
    #popover="ngbPopover"
    class="f-circle bg-base"
    [ngClass]="lineClasses"
    [attr.r]="circleRadius"
    [attr.stroke]="'grey'"
    [attr.opacity]="0"
    [attr.stroke-width]="lineWidth"
    [ngbPopover]="popoverTemplate && popoverTemplateOutlet"
    [disablePopover]="!pointerPositionData?.popoverData"
    [popoverClass]="popoverClass"
    [placement]="popoverPlacement"
    [triggers]="popoverTriggers"
    [container]="'body'"
  ></circle>

  <ng-template #popoverTemplateOutlet>
    <ng-container
      *ngTemplateOutlet="$any(popoverTemplate); context: { $implicit: pointerPositionData?.popoverData }"
    ></ng-container>
  </ng-template>
</svg>
