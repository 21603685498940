<div class="d-flex flex-column h-100">
  <ca-top-nav [flexibleTopNav]="flexibleMobileNav">
    <ng-content select="[caTopNavSearch]"></ng-content>
  </ca-top-nav>

  <div class="d-flex flex-fill overflow-hidden">
    <ca-side-nav [navigationMap]="sideNavigationMap$ | async" [flexibleSideNav]="flexibleMobileNav"></ca-side-nav>
    <div class="flex-fill d-flex flex-column w-100 overflow-hidden">
      <ng-container fDrawerDefaultContainer>
        <ca-breadcrumbs></ca-breadcrumbs>
        <div class="flex-fill overflow-auto mt-n1 pt-1" #scrollableElement>
          <ng-content></ng-content>
        </div>
      </ng-container>
    </div>
  </div>
</div>
