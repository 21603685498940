<ng-container *ngFor="let column of customFieldsColumns">
  <kendo-grid-column
    caExportableColumn
    caCustomFieldColumn
    [title]="column.name"
    [field]="column.name"
    [width]="column.width"
    [sortable]="sortable"
    [exportField]="column.id?.toString() ?? column.name"
    [exportFormat]="column.type === 'Numeric' ? '#,##0.###' : 'default'"
    [headerClass]="column.headerClass ?? 'text-center'"
    [ngClass]="column.cssClass ?? 'text-center'"
    [minResizableWidth]="column.width"
    [media]="media"
  >
    <ng-template kendoGridCellTemplate let-item>
      <ng-container *ngFor="let f of item.customFields">
        <ca-show-all
          *ngIf="f.fieldName === column.name"
          [items]="f.value"
          [isNumber]="column.type === 'Numeric'"
        ></ca-show-all>
      </ng-container>
    </ng-template>
  </kendo-grid-column>
</ng-container>
