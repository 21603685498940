import { Action, createReducer, on } from '@ngrx/store';
import { CurrencyResponse } from '@capital-access/common/standing-data';
import { LoadStatus } from '@capital-access/common/utils';
import { fallbackCurrency } from '../../models/constants';
import {
  loadSupportedCurrencies,
  loadSupportedCurrenciesFail,
  loadSupportedCurrenciesFallback,
  loadSupportedCurrenciesSuccess
} from './actions';

export interface State {
  availableCurrencies: CurrencyResponse | null;
  loadStatus: LoadStatus | 'Fallback';
}

export const initialState: State = {
  availableCurrencies: null,
  loadStatus: LoadStatus.Pristine
};

const fallbackCurrencies: CurrencyResponse = { data: [fallbackCurrency], totalCount: 1 };

const reducer = createReducer(
  initialState,
  on(loadSupportedCurrencies, state => ({
    ...state,
    loadStatus: LoadStatus.Loading
  })),
  on(loadSupportedCurrenciesSuccess, (state, action) => ({
    ...state,
    availableCurrencies: action.response,
    loadStatus: LoadStatus.Loaded
  })),
  on(loadSupportedCurrenciesFail, state => ({
    ...state,
    availableCurrencies: fallbackCurrencies,
    loadStatus: LoadStatus.Failed
  })),
  on(loadSupportedCurrenciesFallback, state => ({
    ...state,
    availableCurrencies: fallbackCurrencies,
    loadStatus: 'Fallback'
  }))
);

export function currencyReducer(state: State | undefined, action: Action) {
  return reducer(state, action);
}
