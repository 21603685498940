<f-chip
  *ngFor="let item of chipItems; let lastItem = last; let i = index"
  class="mt-2"
  [class.me-1]="!lastItem"
  [class.me-3]="item[groupedField] !== chipItems![i + 1]?.[groupedField] && !lastItem"
  [type]="isSelected(item[idField]) ? ChipType.MultiChoice : ChipType.Enabled"
  [size]="size"
  (chipClick)="onToggle(item)"
  [attr.data-automation-id]="'multi-choice-chip-' + item[idField]"
>
  {{ item | fNestedValue : valuePath }}
</f-chip>
