import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { ScaleLinear, scaleLinear } from 'd3-scale';
import {
  Breakpoint,
  ChartDataEntry,
  ChartDimensions,
  FireflyBarChartComponent
} from '@capital-access/firefly/components';
import { DecilesIndexRange, FactorInfo } from '../models';

@Component({
  selector: 'ca-concentration-bar-chart',
  templateUrl: './concentration-bar-chart.component.html',
  styleUrls: ['./concentration-bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConcentrationBarChartComponent extends FireflyBarChartComponent implements OnChanges {
  override chartComponent = ConcentrationBarChartComponent;

  @Input() override fitContainer = true;
  @Input() override showBorders = false;
  @Input() override showXAxisDomain = true;
  @Input() override showYAxisGridlines = false;
  @Input() override showYAxisTicksLabels = false;
  @Input() override showXAxisTicksLabels = false;
  @Input() override showBarsOptionalData = true;
  @Input() override infoBubbleScaleFactor = 0.5;
  @Input() override xAxisTickLabelOffset = 25;

  @Input() factorInfo!: FactorInfo;
  @Input() highlightedIndexRange!: DecilesIndexRange;
  @Input() decileConcentrationClasses!: string[];

  @Input() override view: ChartDimensions = {
    width: 180,
    height: 20
  };

  override padding = 0.1;
  override minBarWidth = 10;

  override ngOnChanges() {
    this.data = this.factorInfo.decile.values.map((value, index) => {
      const cssClass = this.getConcentrationClass(value);
      const optional = index === this.factorInfo.securityDecileIndex ? { value: ' ' } : null;
      const heightCoefficient = 0.9;
      const originalValue = value;

      value = value === 0 ? value : this.minBarWidth + heightCoefficient * value;

      const obj = { name: index.toLocaleString(), value, originalValue, cssClass } as ChartDataEntry;
      if (optional) obj.optional = optional;
      return obj;
    });

    super.ngOnChanges();
  }

  override getYScale(view: ChartDimensions): ScaleLinear<number, number> {
    this.yDomain = this.getYDomain(this.data);
    const scale = scaleLinear().range([view.height, 0]).domain(this.yDomain);

    if (this.interactions.preventMobile && window.innerWidth < Breakpoint.Sm) {
      const step = this.getScaleStep(scale);
      const [min, max] = scale.domain();
      const threshold = (max / 100) * 80;

      if (this.shouldAdjustYScaleDomain(threshold)) {
        scale.domain([min, max + step]);
      }

      return scale;
    } else {
      return scale;
    }
  }

  getConcentrationClass(value: number) {
    if (value < 6) {
      return this.decileConcentrationClasses[0] ?? '';
    } else if (value < 9) {
      return this.decileConcentrationClasses[1] ?? '';
    } else if (value < 15) {
      return this.decileConcentrationClasses[2] ?? '';
    } else if (value < 20) {
      return this.decileConcentrationClasses[3] ?? '';
    } else {
      return this.decileConcentrationClasses[4] ?? '';
    }
  }
}
