import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { FireflySpinnerModule } from '../loading-indicators';
import { FireflyTooltipModule } from '../tooltips';
import { FireflyDropdownUtilsModule } from './directives/dropdown.utils.module';
import { FireflyNestedDropdownDirective } from './directives/nested-dropdown.directive';
import { FireflyBaseDropdownComponent } from './dropdown/base-dropdown.component';
import { FireflyDropdownComponent } from './dropdown/dropdown.component';
import { FireflyMobileDropdownComponent } from './dropdown/mobile-dropdown.component';
import { FireflyDropdownItemDirective } from './dropdown-item/dropdown-item.directive';
import { FireflySplitDropdownComponent } from './split-dropdown/split-dropdown.component';

const components = [
  FireflyDropdownComponent,
  FireflySplitDropdownComponent,
  FireflyDropdownItemDirective,
  FireflyNestedDropdownDirective,
  FireflyMobileDropdownComponent
];

@NgModule({
  imports: [CommonModule, NgbDropdownModule, FireflyDropdownUtilsModule, FireflyTooltipModule, FireflySpinnerModule],
  declarations: [...components, FireflyBaseDropdownComponent],
  exports: [...components]
})
export class FireflyDropdownModule {}
