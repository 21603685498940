import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GridModule } from '@progress/kendo-angular-grid';
import { FireflyInteractiveGridModule } from '../../../table';
import { FireflySkeletonLoaderModule } from '../skeleton-loader.module';
import { FireflySkeletonTableLoaderComponent } from './skeleton-table-loader.component';

@NgModule({
  imports: [CommonModule, FireflySkeletonLoaderModule, GridModule, FireflyInteractiveGridModule],
  declarations: [FireflySkeletonTableLoaderComponent],
  exports: [FireflySkeletonTableLoaderComponent]
})
export class FireflySkeletonTableLoaderModule {}
