import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonFormattingModule } from '@capital-access/common/formatting';
import {
  FireflyBubbleSeriesModule,
  FireflyChartBoilerplateModule,
  FireflyPopoverModule
} from '@capital-access/firefly/components';
import { ConcentrationBarChartComponent } from './concentration-bar-chart/concentration-bar-chart.component';
import { ConcentrationBarSeriesComponent } from './concentration-bar-series/concentration-bar-series.component';
import { ConcentrationChartComponent } from './concentration-chart.component';
import { ConcentrationChartMobilePopoverComponent } from './concentration-chart-mobile-popover/concentration-chart-mobile-popover.component';

@NgModule({
  imports: [
    CommonModule,
    CommonFormattingModule,
    FireflyChartBoilerplateModule,
    FireflyBubbleSeriesModule,
    FireflyPopoverModule
  ],
  declarations: [
    ConcentrationChartComponent,
    ConcentrationBarChartComponent,
    ConcentrationBarSeriesComponent,
    ConcentrationChartMobilePopoverComponent
  ],
  exports: [ConcentrationChartComponent]
})
export class ConcentrationChartModule {}
