import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, OnChanges, Output } from '@angular/core';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import { ChipSize } from '../models/chip-size';
import { ChipType } from '../models/chip-type';

@Component({
  selector: 'f-multi-choice-chip-group',
  templateUrl: './multi-choice-chip-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyMultiChoiceChipGroupComponent implements OnChanges {
  @Input() items: Record<string, unknown>[] | null = [];
  @Input() valuePath: string = 'text';
  @Input() idField: string = 'id';
  @Input() selectedIds: unknown[] = [];
  @Input() size: ChipSize = ChipSize.Xs;
  @Input() groupedField: string = '';

  ChipType = ChipType;
  chipItems: Record<string, unknown>[] | null = [];

  @Output() toggle = new EventEmitter<unknown>();

  @HostBinding('class') class = 'd-flex flex-wrap mt-n2';

  ngOnChanges() {
    this.chipItems = this.groupedField ? flatten(Object.values(groupBy(this.items, this.groupedField))) : this.items;
  }

  isSelected(id: unknown) {
    return this.selectedIds.includes(id);
  }

  onToggle(item: unknown) {
    this.toggle.emit(item);
  }
}
