import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import chunk from 'lodash/chunk';
import flatten from 'lodash/flatten';
import { from, Observable } from 'rxjs';
import { map, mergeMap, toArray } from 'rxjs/operators';
import { ExcelColumn, toHttpParamsObject } from '@capital-access/common/utils';
import { CompositeId } from '../contracts/composite-id';
import { ProfileType } from '../contracts/profile-id.models';
import {
  ContactInformationDto,
  FundInformationDto,
  InstitutionInformationDto,
  ProfileField
} from '../dtos/information-request.model';
import { TotalAssets } from '../models/assets-under-management.model';
import { ContactIdInformationResponse, ContactInformationResponse } from '../models/contact-information.models';
import { ContactRequest } from '../models/contact-request.model';
import { ContactsFilter, ContactsIdsFilter } from '../models/contacts-filter.model';
import { CoverageResponse } from '../models/coverage-card.models';
import { InstitutionCrmAssociations } from '../models/crm-associations.models';
import { FundIdInformation, FundInformationResponse } from '../models/fund-information.models';
import { FundRequest } from '../models/fund-request.model';
import { ProfileIdInformation } from '../models/information.models';
import { InstitutionFundsRequest } from '../models/institution-funds-request.model';
import { InstitutionIdInformation, InstitutionInformationResponse } from '../models/institution-information.models';
import { InstitutionRequest } from '../models/institution-request.model';
import { InvestorTypeStats } from '../models/institution-stats.models';
import { InvestmentApproachDto } from '../models/investment-approach';
import { ProfilesPaging, ProfilesSearchResult } from '../models/profiles-request.models';
import { CompositeContact, SearchContactsCriteria } from '../models/search-contact.model';
import { CompositeInstitution, SearchInstitutionsCriteria } from '../models/search-institution.model';
import { searchContactsParams, searchInstitutionsParams } from '../utils/search-params';

@Injectable({
  providedIn: 'root'
})
export class ProfileRepository {
  private readonly apiBase = '/api/profile';

  constructor(private readonly http: HttpClient) {}

  getContactInformation(id: CompositeId, profileFields?: ProfileField[]): Observable<ContactInformationDto> {
    const params = profileFields ? { fields: profileFields } : undefined;
    return this.http.get<ContactInformationDto>(`${this.apiBase}/contacts/${id}`, { params });
  }

  getContactsInformation(ids: CompositeId[], profileFields?: ProfileField[]): Observable<ContactInformationDto[]> {
    return this.getProfilesInformation<ContactInformationDto>(`${this.apiBase}/contacts`, ids, profileFields);
  }

  getInternalContacts(
    profileFields?: ProfileField[],
    pagingParams?: ProfilesPaging
  ): Observable<ProfilesSearchResult<ContactInformationDto>> {
    const params = toHttpParamsObject({ fields: profileFields, ...pagingParams });
    return this.http.get<ProfilesSearchResult<ContactInformationDto>>(`${this.apiBase}/contacts/internal-contacts`, {
      params
    });
  }

  getPrimaryContacts(ids: CompositeId[]) {
    const params = {
      compositeKeys: ids.map(id => id.toString()),
      fields: ['id', 'name']
    };
    return this.http.get<Record<string, ContactInformationDto[]>>(`${this.apiBase}/companies/primary-contacts`, {
      params
    });
  }

  getFullContactInformation(id: CompositeId): Observable<ContactIdInformationResponse & ContactInformationResponse> {
    return this.http.get<ContactIdInformationResponse & ContactInformationResponse>(`${this.apiBase}/contacts/${id}`);
  }

  getContactFunds(id: CompositeId, pagingParams: ProfilesPaging) {
    return this.http.get<ProfilesSearchResult<FundInformationDto>>(`${this.apiBase}/contacts/${id}/funds`, {
      params: toHttpParamsObject(pagingParams)
    });
  }

  getContactInvestmentApproach(id: number): Observable<InvestmentApproachDto> {
    return this.http.get<InvestmentApproachDto>(`${this.apiBase}/contacts/${id}/investment-approach`);
  }

  getInstitutionInformation(id: CompositeId, profileFields?: ProfileField[]): Observable<InstitutionInformationDto> {
    const params = profileFields ? { fields: profileFields } : undefined;
    return this.http.get<InstitutionInformationDto>(`${this.apiBase}/companies/${id}`, { params });
  }

  getInstitutionsInformation(
    ids: CompositeId[],
    profileFields?: ProfileField[]
  ): Observable<InstitutionInformationDto[]> {
    return this.getProfilesInformation<InstitutionInformationDto>(`${this.apiBase}/companies`, ids, profileFields);
  }

  getFullInstitutionInformation(
    id: CompositeId
  ): Observable<InstitutionInformationResponse & InstitutionIdInformation> {
    return this.http.get<InstitutionInformationResponse & InstitutionIdInformation>(`${this.apiBase}/companies/${id}`);
  }

  getInstitutionFunds(
    id: number,
    request: InstitutionFundsRequest
  ): Observable<ProfilesSearchResult<FundInformationResponse & FundIdInformation>> {
    return this.http.get<ProfilesSearchResult<FundInformationResponse & FundIdInformation>>(
      `${this.apiBase}/companies/${id}/funds`,
      {
        params: toHttpParamsObject(request)
      }
    );
  }

  getInstitutionInvestmentApproach(id: number): Observable<InvestmentApproachDto> {
    return this.http.get<InvestmentApproachDto>(`${this.apiBase}/companies/${id}/investment-approach`);
  }

  getFundInformation(id: CompositeId, profileFields?: ProfileField[]): Observable<FundInformationDto> {
    const params = profileFields ? { fields: profileFields } : undefined;
    return this.http.get<FundInformationDto>(`${this.apiBase}/funds/${id}`, { params });
  }

  getFullFundInformation(id: CompositeId): Observable<FundInformationResponse & FundIdInformation> {
    return this.http.get<FundInformationResponse & FundIdInformation>(`${this.apiBase}/funds/${id}`);
  }

  addInstitution(data: InstitutionRequest): Observable<number> {
    return this.http.post<number>(`${this.apiBase}/companies`, data);
  }

  updateInstitution(id: CompositeId, data: InstitutionRequest) {
    return this.http.patch(`${this.apiBase}/companies/${id}`, data);
  }

  addContact(data: ContactRequest): Observable<number> {
    return this.http.post<number>(`${this.apiBase}/contacts`, data);
  }

  updateContact(id: CompositeId, data: ContactRequest) {
    return this.http.patch(`${this.apiBase}/contacts/${id}`, data);
  }

  getInstitutionContacts(id: CompositeId, filterParams: ContactsFilter) {
    return this.http.get<ProfilesSearchResult<ContactInformationDto>>(`${this.apiBase}/companies/${id}/contacts`, {
      params: toHttpParamsObject(filterParams)
    });
  }

  getFundContacts(id: CompositeId, filterParams: ContactsFilter) {
    return this.http.get<ProfilesSearchResult<ContactInformationDto>>(`${this.apiBase}/funds/${id}/contacts`, {
      params: toHttpParamsObject(filterParams)
    });
  }

  getInstitutionContactsIds(id: CompositeId, filterParams: ContactsIdsFilter) {
    return this.http.get<{ ids: string[] }>(`${this.apiBase}/companies/${id}/contacts/ids`, {
      params: toHttpParamsObject(filterParams)
    });
  }

  getFundContactsIds(id: CompositeId, filterParams: ContactsIdsFilter) {
    return this.http.get<{ ids: string[] }>(`${this.apiBase}/funds/${id}/contacts/ids`, {
      params: toHttpParamsObject(filterParams)
    });
  }

  updateFund(id: CompositeId, data: FundRequest) {
    return this.http.patch(`${this.apiBase}/funds/${id}`, data);
  }

  getInstitutionTotalAssets(id: number): Observable<TotalAssets> {
    return this.http.get<TotalAssets>(`${this.apiBase}/companies/${id}/assets-under-management`);
  }

  getFundTotalAssets(id: number): Observable<TotalAssets> {
    return this.http.get<TotalAssets>(`${this.apiBase}/funds/${id}/assets-under-management`);
  }

  getCoverage(id: CompositeId): Observable<CoverageResponse> {
    return this.http.get<CoverageResponse>(`${this.apiBase}/contacts/${id}/coverage`);
  }

  getInstitutionCrmAssociations(id: CompositeId): Observable<InstitutionCrmAssociations> {
    return this.http.get<InstitutionCrmAssociations>(`${this.apiBase}/companies/${id}/crm-associations`);
  }

  deleteInstitution(id: CompositeId) {
    return this.http.delete(`${this.apiBase}/companies/${id}`);
  }

  deleteContact(id: CompositeId) {
    return this.http.delete(`${this.apiBase}/contacts/${id}`);
  }

  deleteFund(id: CompositeId) {
    return this.http.delete(`${this.apiBase}/funds/${id}`);
  }

  getFullProfileId(id: CompositeId, type: ProfileType): Observable<ProfileIdInformation> {
    if (type === ProfileType.Institution) return this.getInstitutionInformation(id, ['id']);
    if (type === ProfileType.Contact) return this.getContactInformation(id, ['id']);
    return this.getFundInformation(id, ['id']);
  }

  updateProfile(id: CompositeId, type: ProfileType) {
    if (type === ProfileType.Institution) return this.updateInstitution(id, {});
    if (type === ProfileType.Contact) return this.updateContact(id, {});
    return this.updateFund(id, {});
  }

  getWithdrawalList(fileName: string, title: string, excelColumns: ExcelColumn[]) {
    const requestBody = {
      fileName,
      title,
      columns: excelColumns
    };

    return this.http.post(`${this.apiBase}/contacts/optout/report`, requestBody, { responseType: 'blob' });
  }

  searchContactsIds(searchCriteria: SearchContactsCriteria): Observable<number[]> {
    return this.http.get<number[]>(`${this.apiBase}/contacts/search/ids`, {
      params: searchContactsParams(searchCriteria)
    });
  }

  searchContacts(searchCriteria: SearchContactsCriteria): Observable<ProfilesSearchResult<CompositeContact>> {
    return this.http.get<ProfilesSearchResult<CompositeContact>>(`${this.apiBase}/contacts/search`, {
      params: searchContactsParams(searchCriteria)
    });
  }

  searchInstitutionsIds(searchCriteria: SearchInstitutionsCriteria): Observable<number[]> {
    return this.http.get<number[]>(`${this.apiBase}/companies/search/ids`, {
      params: searchInstitutionsParams(searchCriteria)
    });
  }

  searchInstitutions(
    searchCriteria: SearchInstitutionsCriteria
  ): Observable<ProfilesSearchResult<CompositeInstitution>> {
    return this.http.get<ProfilesSearchResult<CompositeInstitution>>(`${this.apiBase}/companies/search`, {
      params: searchInstitutionsParams(searchCriteria)
    });
  }

  getInstitutionInvestorTypeStats(): Observable<InvestorTypeStats[]> {
    return this.http.get<InvestorTypeStats[]>(`${this.apiBase}/companies/investor-types/stats`);
  }

  private getProfilesInformation<T>(url: string, ids: CompositeId[], profileFields?: ProfileField[]) {
    const maxConcurrentRequests = 5;
    const maxChunkSize = 150; // profile api limits to 150 items / request

    const chunks = chunk(ids, maxChunkSize);

    return from(chunks).pipe(
      mergeMap(idsChunk => {
        const params: { [key: string]: string[] } = {
          compositeKeys: idsChunk.map(o => o.toString())
        };

        if (profileFields) {
          params.fields = profileFields;
        }

        return this.http.get<T[]>(url, { params });
      }, maxConcurrentRequests),
      toArray(),
      map(responses => flatten(responses))
    );
  }
}
