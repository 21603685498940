import { HttpParams } from '@angular/common/http';
import { SearchContactsCriteria } from '../models/search-contact.model';
import { SearchInstitutionsCriteria } from '../models/search-institution.model';

export function searchContactsParams(searchCriteria: SearchContactsCriteria): HttpParams {
  let params = new HttpParams()
    .set('sort', searchCriteria.sort)
    .set('order', searchCriteria.order)
    .set('skip', searchCriteria.skip || 0)
    .set('take', searchCriteria.take || 0);

  if (searchCriteria.name) params = params.set('name', searchCriteria.name);

  if (searchCriteria.source) params = params.set('source', searchCriteria.source);

  if (searchCriteria.primaryContactOnly) params = params.set('primaryContactOnly', searchCriteria.primaryContactOnly);

  if (searchCriteria.jobTitle) params = params.set('jobTitle', searchCriteria.jobTitle);

  if (searchCriteria.importId) params = params.append('importIds', searchCriteria.importId);

  if (searchCriteria.withoutPrimaryCompanyOnly)
    params = params.set('withoutPrimaryCompanyOnly', searchCriteria.withoutPrimaryCompanyOnly);

  if (searchCriteria.listIds?.length)
    searchCriteria.listIds.forEach(id => {
      params = params.append('listIds', id);
    });

  if (searchCriteria.primaryInstitutionIds?.length)
    searchCriteria.primaryInstitutionIds.forEach(id => {
      params = params.append('primaryCompanyIds', id);
    });

  if (searchCriteria.types?.length) {
    searchCriteria.types.forEach(type => {
      params = params.append('types', type);
    });
  }

  if (searchCriteria.customFieldsFilter?.length) {
    searchCriteria.customFieldsFilter.forEach(item => {
      params = params.append('customFieldsFilter', item);
    });
  }

  if (searchCriteria.selectionFields?.length) {
    searchCriteria.selectionFields.forEach(selectionField => {
      params = params.append('selectionFields', selectionField);
    });
  }

  if (searchCriteria.isExcludedFromMail != undefined)
    params = params.set('isExcludedFromMail', searchCriteria.isExcludedFromMail);

  if (searchCriteria.regionIds?.length)
    searchCriteria.regionIds.forEach(id => {
      params = params.append('regionIds', id);
    });

  if (searchCriteria.countryIds?.length)
    searchCriteria.countryIds.forEach(id => {
      params = params.append('countryIds', id);
    });

  return params;
}

export function searchInstitutionsParams(searchCriteria: SearchInstitutionsCriteria): HttpParams {
  let params = new HttpParams()
    .set('sort', searchCriteria.sort)
    .set('order', searchCriteria.order)
    .set('skip', searchCriteria.skip || 0)
    .set('take', searchCriteria.take || 0);

  if (searchCriteria.name) params = params.set('name', searchCriteria.name);

  if (searchCriteria.source) params = params.set('source', searchCriteria.source);

  if (searchCriteria.activist) params = params.set('activistType', searchCriteria.activist);

  if (searchCriteria.hasPrimaryContacts != undefined)
    params = params.set('hasPrimaryContacts', searchCriteria.hasPrimaryContacts);

  if (searchCriteria.hasContacts != undefined) params = params.set('hasContacts', searchCriteria.hasContacts);

  if (searchCriteria.styleIds?.length)
    searchCriteria.styleIds.forEach(id => {
      params = params.append('styleIds', id);
    });

  if (searchCriteria.listIds?.length)
    searchCriteria.listIds.forEach(id => {
      params = params.append('listIds', id);
    });

  if (searchCriteria.customFieldsFilter?.length) {
    searchCriteria.customFieldsFilter.forEach(item => {
      params = params.append('customFieldsFilter', item);
    });
  }

  if (searchCriteria.investorTypeIds?.length)
    searchCriteria.investorTypeIds.forEach(id => {
      params = params.append('investorTypeIds', id);
    });

  if (searchCriteria.regionIds?.length)
    searchCriteria.regionIds.forEach(id => {
      params = params.append('regionIds', id);
    });

  if (searchCriteria.investmentCenterIds?.length)
    searchCriteria.investmentCenterIds.forEach(id => {
      params = params.append('investmentCenterIds', id);
    });

  if (searchCriteria.countryIds?.length)
    searchCriteria.countryIds.forEach(id => {
      params = params.append('countryIds', id);
    });

  return params;
}
