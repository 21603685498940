import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { CommonLazyLoadingService } from '@capital-access/common/lazy-loading';
import {
  addNewList,
  addProfilesToExistingList,
  addProfilesToNewList,
  editList,
  ListType,
  openListManagement,
  ProfileId,
  showListDetailedView
} from '@capital-access/lists/common';
import { ADD_TO_LIST_MODULE, ADD_TO_LIST_MODULE_ID } from '../models/add-to-list-module-id.model';
import { CREATE_LIST_MODULE, CREATE_LIST_MODULE_ID } from '../models/create-list-module-id.model';
import { EDIT_LIST_MODULE, EDIT_LIST_MODULE_ID } from '../models/edit-list-module-id.model';
import { LIST_DETAILED_VIEW_MODULE, LIST_DETAILED_VIEW_MODULE_ID } from '../models/list-detailed-view-module-id.model';
import { LIST_MANAGEMENT_MODULE, LIST_MANAGEMENT_MODULE_ID } from '../models/list-management-module-id.model';

@Injectable()
export class ListsActionsLazyEffects {
  addNewList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addNewList),
        switchMap(({ listType, showBackButton }) => this.loadCreateListModule(!!showBackButton, listType))
      ),
    { dispatch: false }
  );

  addProfilesToNewList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addProfilesToNewList),
        switchMap(({ listType, ids }) => this.loadCreateListModule(true, listType, ids))
      ),
    { dispatch: false }
  );

  addProfilesToExistingList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addProfilesToExistingList),
      switchMap(listItems =>
        this.lazyLoadingService
          .loadModule(ADD_TO_LIST_MODULE_ID, ADD_TO_LIST_MODULE)
          .pipe(map(moduleRef => moduleRef.instance.showAddToExistingList(listItems)))
      )
    )
  );

  showListDetailedView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(showListDetailedView),
      switchMap(({ list, showBackButton }) =>
        this.lazyLoadingService
          .loadModule(LIST_DETAILED_VIEW_MODULE_ID, LIST_DETAILED_VIEW_MODULE)
          .pipe(map(moduleRef => moduleRef.instance.showDetailedListDrawer(list, showBackButton)))
      )
    )
  );

  editList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editList),
        switchMap(({ list, showBackButton }) =>
          this.lazyLoadingService
            .loadModule(EDIT_LIST_MODULE_ID, EDIT_LIST_MODULE)
            .pipe(switchMap(moduleRef => moduleRef.instance.showEditList(list, showBackButton)))
        )
      ),
    { dispatch: false }
  );
  listManagement$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openListManagement),
        switchMap(({ listType }) =>
          this.lazyLoadingService
            .loadModule(LIST_MANAGEMENT_MODULE_ID, LIST_MANAGEMENT_MODULE)
            .pipe(switchMap(moduleRef => moduleRef.instance.openListManagementDrawer(listType)))
        )
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private lazyLoadingService: CommonLazyLoadingService) {}

  private loadCreateListModule(showBackButton: boolean, listType?: ListType, items?: ProfileId[]) {
    return this.lazyLoadingService
      .loadModule(CREATE_LIST_MODULE_ID, CREATE_LIST_MODULE)
      .pipe(switchMap(moduleRef => moduleRef.instance.showCreateList(showBackButton, listType, items)));
  }
}
