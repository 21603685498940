import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { SystemSettingsService } from '@capital-access/common/settings';
import { User, UserRepository } from '@capital-access/common/user';
import { LoadStatus } from '@capital-access/common/utils';

@Component({
  selector: 'ca-contact-support',
  templateUrl: './contact-support.component.html',
  styleUrls: ['./contact-support.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSupportComponent implements OnInit {
  settings = this.systemSettings.getSettings().contactSupport;
  loadCsAdvisorStatus$ = new BehaviorSubject(LoadStatus.Pristine);
  csAdvisorData$!: Observable<User | null>;
  loadStatus = LoadStatus;

  constructor(
    private store: Store,
    private userRepository: UserRepository,
    private systemSettings: SystemSettingsService
  ) {}

  ngOnInit() {
    this.csAdvisorData$ = this.store.select(getFeatureToggle('cs-info-CPD-1797')).pipe(
      switchMap(allowed => {
        if (!allowed) return of(null);
        requestAnimationFrame(() => this.loadCsAdvisorStatus$.next(LoadStatus.Loading));
        return this.userRepository.getUserAccount().pipe(
          switchMap(account => {
            if (!account.csAdvisorId) {
              this.loadCsAdvisorStatus$.next(LoadStatus.Loaded);
              return of(null);
            }
            return this.userRepository.getUserInfoById(account.csAdvisorId);
          }),
          tap(() => this.loadCsAdvisorStatus$.next(LoadStatus.Loaded)),
          catchError(() => this.handleCsAdvisorError())
        );
      })
    );
  }

  reload() {
    location.reload();
  }

  private handleCsAdvisorError(): Observable<null> {
    this.loadCsAdvisorStatus$.next(LoadStatus.Failed);
    return of(null);
  }
}
