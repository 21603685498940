import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { createProfileReportFinished, openProfileReportDrawer } from '@capital-access/common/actions';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { LocalizationService } from '@capital-access/common/localization';
import { DrawerOutput, DrawerPosition, DrawerType, FireflyDrawerService } from '@capital-access/firefly/components';
import { ProfileType } from '@capital-access/profiles/common';
import { LOCALIZATION_KEY } from '../../constants';
import { getSavedSections } from '../../core/utils/section-settings.utils';
import { ProfileReportDrawerComponent } from '../components/profile-report-drawer/profile-report-drawer.component';
import { CreateProfileReportDto } from '../models/profile-report.models';
import { getConditionalReportSections, getDefaultSections } from '../models/profile-report-sections';

@Injectable()
export class ProfileReportDrawerEffects {
  openDrawer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openProfileReportDrawer),
      withLatestFrom(
        this.localization.getLocalization(LOCALIZATION_KEY),
        this.store.select(getFeatureToggle('profile-report-custom-fields-SIRS-3166'))
      ),
      switchMap(([action, localization, isCustomFieldsToggleEnabled]) => {
        const conditionalSections = getConditionalReportSections(isCustomFieldsToggleEnabled);
        const drawer = this.drawerService.openDrawer(
          {
            title: localization[`${action.profileType}DrawerHeader`],
            position: DrawerPosition.Right,
            type: DrawerType.Form,
            dataLayoutId: 'modify-profile-report-drawer'
          },
          ProfileReportDrawerComponent,
          {
            request: CreateProfileReportDto.create(action.profiles, action.profileType),
            filteredSections: getSavedSections(
              action.profileType,
              getDefaultSections(action.profileType as ProfileType),
              conditionalSections
            )
          }
        );

        return drawer.onClose().pipe(
          take(1),
          filter((output: DrawerOutput) => !output || !output['reportInProgress']),
          map(() => createProfileReportFinished())
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private localization: LocalizationService,
    private drawerService: FireflyDrawerService,
    private store: Store
  ) {}
}
