import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FireflyBaseDropdownComponent } from './base-dropdown.component';

@Component({
  selector: 'f-dropdown',
  templateUrl: './dropdown.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FireflyDropdownComponent extends FireflyBaseDropdownComponent {
  @Input() tooltipPlacement: string = 'bottom-right';
  @Input() tooltipContainer!: 'body' | null;
  @Input() loading: boolean = false;
  @Input() menuCssClass: string = '';
  @Input() tabbable = true;

  @Output() dropdownClose = new EventEmitter();

  disableTooltip = false;
  tooltipClass = '';

  onDropdownOpenChange(isOpen: boolean) {
    if (!isOpen) this.dropdownClose.emit();
    if (!this.tooltip) return;
    setTimeout(() => {
      this.tooltipClass = isOpen ? 'd-none' : '';
      this.disableTooltip = isOpen;
      this.cdr.detectChanges();
    });
  }
}
