import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Account } from './models/account';
import { User } from './models/user';
import { UserProfile } from './models/user-profile';

@Injectable()
export abstract class UserRepository {
  abstract getUserProfile(): Observable<UserProfile>;
  abstract getUserAccount(): Observable<Account>;
  abstract getUserInfoById(id: number): Observable<User>;
}
