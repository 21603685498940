import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseBadgeComponent } from './base-badge/base-badge.component';
import { ChangeBadgeComponent } from './change-badge/change-badge.component';
import { SimpleChangeBadgeComponent } from './simple-change-badge/simple-change-badge.component';
import { TrendBadgeComponent } from './trend-badge/trend-badge.component';

const components = [ChangeBadgeComponent, SimpleChangeBadgeComponent, TrendBadgeComponent];

@NgModule({
  imports: [CommonModule],
  declarations: [BaseBadgeComponent, ...components],
  exports: [...components]
})
export class CommonBadgesModule {}
