import { Injectable } from '@angular/core';
import { NgbConfig, NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class FireflyNavConfig extends NgbNavConfig {
  constructor(ngbConfig: NgbConfig) {
    super(ngbConfig);
    this.animation = true;
  }
}
