<ng-container *ngIf="responsive; else chips">
  <div class="d-none d-lg-block">
    <ng-container *ngTemplateOutlet="chips"></ng-container>
  </div>

  <f-dropdown
    class="d-lg-none"
    [disabled]="disabled"
    [btnStyle]="btnStyle"
    [placement]="dropdownPlacement"
    [text]="selectedItem | fNestedValue : valuePath"
  >
    <f-list>
      <f-list-item fDropdownItem *ngFor="let item of items" (click)="onSelect(item)">
        {{ item | fNestedValue : valuePath }}
      </f-list-item>
    </f-list>
  </f-dropdown>
</ng-container>

<ng-template #chips>
  <f-chip
    *ngFor="let item of items; let lastItem = last"
    [class.me-1]="!lastItem"
    [size]="chipSize"
    [disabled]="disabled"
    [type]="getChipType(item)"
    [rootCssClass]="rootCssClass"
    [outline]="outline"
    (chipClick)="onSelect(item)"
    [attr.data-automation-id]="'single-choice-chip-' + item[idField]"
  >
    {{ item | fNestedValue : valuePath }}
    <i
      *ngIf="chipWithIcon"
      class="f-i f-i-info"
      triggers="hover"
      container="body"
      [ngbPopover]="iconPopoverContent"
      placement="top"
    ></i>
  </f-chip>
</ng-template>
