import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { getFeatureToggle } from '@capital-access/common/feature-toggle';
import { LocalizationService } from '@capital-access/common/localization';
import { SystemSettingsService } from '@capital-access/common/settings';
import { User, UserRepository } from '@capital-access/common/user';
import { LoadStatus } from '@capital-access/common/utils';
import {
  DrawerPosition,
  DrawerType,
  FireflyDrawerContent,
  FireflyDrawerService
} from '@capital-access/firefly/components';
import { exportWithdrawList } from './+state/contact-support-drawer.actions';
import { DisclaimerComponent } from './disclaimer.component';

@Component({
  templateUrl: './contact-support.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactSupportComponent extends FireflyDrawerContent<void> implements OnInit {
  settings = this.settingsService.getSettings();
  loadCsAdvisorStatus$ = new BehaviorSubject(LoadStatus.Pristine);
  csAdvisorData$!: Observable<User | null>;
  loadStatus = LoadStatus;

  constructor(
    private drawerService: FireflyDrawerService,
    private localization: LocalizationService,
    private settingsService: SystemSettingsService,
    private userRepository: UserRepository,
    private store: Store
  ) {
    super();
  }

  get currentYear() {
    return new Date().getFullYear();
  }

  ngOnInit() {
    this.csAdvisorData$ = this.store.select(getFeatureToggle('cs-info-CPD-1797')).pipe(
      switchMap(allowed => {
        if (!allowed) return of(null);
        requestAnimationFrame(() => this.loadCsAdvisorStatus$.next(LoadStatus.Loading));
        return this.userRepository.getUserAccount().pipe(
          switchMap(account => {
            if (!account.csAdvisorId) {
              this.loadCsAdvisorStatus$.next(LoadStatus.Loaded);
              return of(null);
            }
            return this.userRepository.getUserInfoById(account.csAdvisorId);
          }),
          tap(() => this.loadCsAdvisorStatus$.next(LoadStatus.Loaded)),
          catchError(() => this.handleCsAdvisorError())
        );
      })
    );
  }

  showDisclaimer() {
    this.localization
      .getLocalization('navigation')
      .pipe(
        map(({ disclaimer }) => {
          return this.drawerService.openDrawer(
            {
              title: disclaimer,
              type: DrawerType.Form,
              position: DrawerPosition.Right,
              addBackButton: true
            },
            DisclaimerComponent
          );
        }),
        take(1)
      )
      .subscribe();
  }

  exportContactsWithdrawList() {
    this.store.dispatch(exportWithdrawList());
  }

  private handleCsAdvisorError(): Observable<null> {
    this.loadCsAdvisorStatus$.next(LoadStatus.Failed);
    return of(null);
  }
}
