import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { pessimisticUpdate } from '@nrwl/angular';
import differenceBy from 'lodash/differenceBy';
import { EMPTY } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { Log } from '@capital-access/common/logging';
import { UserSettingsRepository } from '../user-settings.repository';
import { saveSerializedSettings, saveSetting, saveSettingsFailure, saveSettingsSuccess } from './settings.actions';
import { getSettings } from './settings.selectors';

@Injectable()
export class CommonUserSettingsEffects {
  saveSetting$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveSetting),
      pessimisticUpdate({
        run: action => {
          return this.settingsRepository
            .saveSettings([{ id: action.key, value: action.value }])
            .pipe(map(result => saveSettingsSuccess({ settings: result })));
        },
        onError: (action, error) => {
          return saveSettingsFailure({ error: error, failedIds: [action.key] });
        }
      })
    )
  );

  saveSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveSerializedSettings),
      withLatestFrom(this.store.select(getSettings)),
      pessimisticUpdate({
        run: ({ data }, currentSettings) => {
          if (currentSettings) {
            const diff = currentSettings.diff(data);
            const unchanged = differenceBy(data, diff, 'id');
            return this.settingsRepository
              .saveSettings(diff)
              .pipe(map(result => saveSettingsSuccess({ settings: result.concat(unchanged) })));
          } else {
            Log.warn('Received empty settings save request');
            return EMPTY;
          }
        },
        onError: (action, error) => {
          return saveSettingsFailure({
            error: error,
            failedIds: action.data.map(s => s.id)
          });
        }
      })
    )
  );

  constructor(private actions$: Actions, private store: Store, private settingsRepository: UserSettingsRepository) {}
}
