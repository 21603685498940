import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LIST_ITEMS_SIZE, LIST_SIZE, LISTS_API_BASE_URL, LISTS_API_BASE_URL_V2 } from '../constants';
import {
  AddedListItemsResponse,
  DeletedListResponse,
  List,
  ListItemsResponse,
  ListsResponse,
  ListType,
  ProfileId,
  ProfileListItemsIds,
  RemovedListItemsResponse,
  RemovedProfileFromListsResponse,
  SearchFilter,
  SearchListsCriteria,
  SecurityListItemsIds
} from '../models';

@Injectable()
export class ListsCommonRepository {
  constructor(private http: HttpClient) {}

  public removeProfilesFromList(
    listId: number,
    items: ProfileId[],
    listType: ListType
  ): Observable<RemovedListItemsResponse> {
    const type = listType === ListType.Contact ? 'contacts' : 'companies';
    return this.http.request<RemovedListItemsResponse>('delete', `${LISTS_API_BASE_URL}lists/${listId}/${type}`, {
      body: { items }
    });
  }

  removeProfileFromLists(prId: number | null, crmId: number | null, listType: ListType) {
    const type = listType === ListType.Contact ? 'contacts' : 'companies';
    const profileId = `${prId || ''}|${crmId || ''}`;
    return this.http.delete<RemovedProfileFromListsResponse>(`${LISTS_API_BASE_URL}${type}/${profileId}`);
  }

  getListItems(listId: number, listType: ListType, skip: number, take?: number): Observable<ListItemsResponse> {
    const params = new HttpParams({
      fromObject: { skip: `${skip}`, take: `${take ?? LIST_ITEMS_SIZE}` }
    });
    if (listType === ListType.Institution) {
      return this.getInstitutionListItems(listId, params);
    } else if (listType === ListType.Security) {
      return this.getSecurityListItems(listId, params);
    } else return this.getContactListItems(listId, params);
  }

  getListItemsIds(listId: number, listType: ListType): Observable<SecurityListItemsIds | ProfileListItemsIds> {
    return listType === ListType.Security
      ? this.getSecurityListItemsIds(listId)
      : this.getProfileListItemsIds(listId, listType);
  }

  getSecurityListItemsIds(listId: number): Observable<SecurityListItemsIds> {
    return this.http.get<SecurityListItemsIds>(`${LISTS_API_BASE_URL}lists/${listId}/securities/ids`);
  }

  getProfileListItemsIds(listId: number, listType: ListType): Observable<ProfileListItemsIds> {
    return listType === ListType.Institution
      ? this.getInstitutionListItemsIds(listId)
      : this.getContactListItemsIds(listId);
  }

  getInstitutionListItemsIds(listId: number): Observable<ProfileListItemsIds> {
    return this.http.get<ProfileListItemsIds>(`${LISTS_API_BASE_URL}lists/${listId}/companies/ids`);
  }

  getContactListItemsIds(listId: number): Observable<ProfileListItemsIds> {
    return this.http.get<ProfileListItemsIds>(`${LISTS_API_BASE_URL}lists/${listId}/contacts/ids`);
  }

  searchList(request: SearchListsCriteria): Observable<ListsResponse> {
    return this.http.post<ListsResponse>(`${LISTS_API_BASE_URL_V2}lists/search`, request);
  }

  searchListIds(request: SearchFilter): Observable<number[]> {
    return this.http.post<number[]>(`${LISTS_API_BASE_URL_V2}lists/search/ids`, request);
  }

  private getInstitutionListItems(listId: number, params: HttpParams): Observable<ListItemsResponse> {
    return this.http.get<ListItemsResponse>(`${LISTS_API_BASE_URL}lists/${listId}/companies`, {
      params
    });
  }

  private getContactListItems(listId: number, params: HttpParams): Observable<ListItemsResponse> {
    return this.http.get<ListItemsResponse>(`${LISTS_API_BASE_URL}lists/${listId}/contacts`, {
      params
    });
  }
  private getSecurityListItems(listId: number, params: HttpParams): Observable<ListItemsResponse> {
    return this.http.get<ListItemsResponse>(`${LISTS_API_BASE_URL}lists/${listId}/securities`, {
      params
    });
  }

  getExistingLists(listType: ListType, skip: number = 0, take?: number): Observable<ListsResponse> {
    const params = new HttpParams({
      fromObject: { type: `${listType}`, skip: `${skip}`, take: `${take ?? LIST_SIZE}` }
    });
    return this.http.get<ListsResponse>(`${LISTS_API_BASE_URL}lists`, { params });
  }

  getList(id: number): Observable<List> {
    return this.http.get<List>(`${LISTS_API_BASE_URL}lists/${id}`);
  }

  public getIncludedLists(type: ListType, { prId, crmId }: ProfileId): Observable<ListsResponse> {
    let params = new HttpParams().set('type', `${type}`);
    if (prId) {
      params = params.set('prId', `${prId}`);
    }
    if (crmId) {
      params = params.set('crmId', `${crmId}`);
    }
    return this.http.get<ListsResponse>(`${LISTS_API_BASE_URL}lists`, { params });
  }

  addProfilesToList(listId: number, items: ProfileId[], listType: ListType): Observable<AddedListItemsResponse> {
    if (listType === ListType.Contact) {
      return this.addContactsToList(listId, items);
    } else return this.addInstitutionsToList(listId, items);
  }

  deleteList(listId: number): Observable<DeletedListResponse> {
    return this.http.delete<DeletedListResponse>(`${LISTS_API_BASE_URL}lists/${listId}`);
  }

  getSortedByPrimaryLists(lists: ListsResponse, defaultId: number): Observable<ListsResponse> {
    const index = lists.result.findIndex(list => list.id === defaultId);
    if (index !== -1) {
      lists.result = this.getSortedPrimaryListByIndex(lists.result, index);
      return of(lists);
    } else {
      return this.getList(defaultId).pipe(
        catchError(() => of(null)),
        map(defaultList => {
          if (!defaultList) return lists;
          lists.result.unshift(defaultList);
          return lists;
        })
      );
    }
  }

  getSortedPrimaryListByIndex<T>(lists: T[], defaultListIndex: number) {
    const defaultList = lists.splice(defaultListIndex, 1)[0];
    lists.unshift(defaultList);
    return lists;
  }

  private addContactsToList(listId: number, items: ProfileId[]): Observable<AddedListItemsResponse> {
    return this.http.post<AddedListItemsResponse>(`${LISTS_API_BASE_URL}lists/${listId}/contacts`, { items });
  }

  private addInstitutionsToList(listId: number, items: ProfileId[]): Observable<AddedListItemsResponse> {
    return this.http.post<AddedListItemsResponse>(`${LISTS_API_BASE_URL}lists/${listId}/companies`, { items });
  }
}
