<div class="mt-3 mb-1" [ngClass]="rootCssClass">
  <span *ngIf="title" class="text-muted me-1">{{ title }}:</span>
  <span class="text-wrap" #valueContent>
    <ng-container *ngIf="showContent; else emptyValue">
      <ng-content></ng-content>
    </ng-container>
  </span>
</div>

<ng-template #emptyValue>
  <span class="text-muted">{{ noDataMessage$ | async }}</span>
</ng-template>
