import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  NgbAccordion,
  NgbAccordionConfig,
  NgbAccordionModule,
  NgbPanel,
  NgbPanelContent,
  NgbPanelTitle
} from '@ng-bootstrap/ng-bootstrap';
import { FireflyAccordionConfig } from './accordion-config';

@NgModule({
  imports: [NgbAccordionModule],
  exports: [NgbAccordion, NgbPanel, NgbPanelTitle, NgbPanelContent]
})
export class FireflyAccordionModule {
  static forRoot(): ModuleWithProviders<FireflyAccordionModule> {
    return {
      ngModule: FireflyAccordionModule,
      providers: [{ provide: NgbAccordionConfig, useClass: FireflyAccordionConfig }]
    };
  }
}
